import { initializeApp } from 'firebase/app';
import {
  browserLocalPersistence,
  browserPopupRedirectResolver,
  browserSessionPersistence,
  initializeAuth
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyCVmwoDr-GrZqIr2cYZxSe1Y6ri2rWOLfc',
  authDomain: 'notifire.botondveress.com',
  projectId: 'notifire-26801',
  storageBucket: 'notifire-26801.appspot.com',
  messagingSenderId: '925008947072',
  appId: '1:925008947072:web:537f0cfd9e9372f50ec365'
};

const app = initializeApp(firebaseConfig);

// app.automaticDataCollectionEnabled = false;

export const auth = initializeAuth(app, {
  persistence: [browserLocalPersistence, browserSessionPersistence],
  popupRedirectResolver: browserPopupRedirectResolver
});

import React from 'react';
import { Navigate, Route, Routes } from 'react-router';

import { urls } from '@/utils/url';

import { NotificationListPage } from '@/pages/NotificationListPage';
import { NotificationDetailPage } from './pages/NotificationDetailPage';

export const PrivateRoutes: React.FC = () => (
  <Routes>
    <Route element={<NotificationListPage />} path={urls.notifications.root} />
    <Route element={<NotificationDetailPage />} path={urls.notifications.one} />
    <Route element={<Navigate replace to={urls.notifications.root} />} path="*" />
  </Routes>
);

import { Language, Locale, getLocale } from '@rchitected/locale';
import flat from 'flat';
import React, { StrictMode } from 'react';
import { Root, createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import 'tailwindcss/tailwind.css';

import { App } from '@/App';
import { Auth } from '@/containers/useAuth';
import { LoadingPage } from '@/pages/LoadingPage';

const root = createRoot(document.getElementById('app')!);

const AppWithLanguage: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [locale, , messages] = Language.useContainer();

  if (!messages.data) return <LoadingPage />;

  return (
    <IntlProvider
      defaultLocale={locale}
      defaultRichTextElements={{ br: (<br />) as any }}
      messages={flat(messages.data)}
      {...{ locale }}
    >
      {children}
    </IntlProvider>
  );
};

function bootstrapApplication(element: Root) {
  element.render(
    <StrictMode>
      <BrowserRouter>
        <Language.Provider initialState={{ locale: getLocale(Locale.English) }}>
          <AppWithLanguage>
            <Auth.Provider>
              <App />
            </Auth.Provider>
          </AppWithLanguage>
        </Language.Provider>
      </BrowserRouter>
    </StrictMode>
  );
}

bootstrapApplication(root);

/* tslint:disable */
/* eslint-disable */
/**
 * Platform API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: botond.veress@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ContextResponse
 */
export interface ContextResponse {
    /**
     * 
     * @type {User}
     * @memberof ContextResponse
     */
    'user': User;
    /**
     * 
     * @type {Array<UserOrganization>}
     * @memberof ContextResponse
     */
    'organizations': Array<UserOrganization>;
}
/**
 * 
 * @export
 * @interface CreateNotificationRequest
 */
export interface CreateNotificationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateNotificationRequest
     */
    'content': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateNotificationRequest
     */
    'broadcast': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateNotificationRequest
     */
    'topic'?: string;
}
/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'topic'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Notification
     */
    'broadcast': boolean;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface NotificationDetail
 */
export interface NotificationDetail {
    /**
     * 
     * @type {string}
     * @memberof NotificationDetail
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDetail
     */
    'topic'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationDetail
     */
    'broadcast': boolean;
    /**
     * 
     * @type {string}
     * @memberof NotificationDetail
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDetail
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface NotificationsResponse
 */
export interface NotificationsResponse {
    /**
     * 
     * @type {Array<Notification>}
     * @memberof NotificationsResponse
     */
    'data': Array<Notification>;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UserOrganization
 */
export interface UserOrganization {
    /**
     * 
     * @type {string}
     * @memberof UserOrganization
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserOrganization
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserOrganization
     */
    'apiKey': string;
}

/**
 * ContextApi - axios parameter creator
 * @export
 */
export const ContextApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves the session context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/context`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContextApi - functional programming interface
 * @export
 */
export const ContextApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContextApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves the session context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContextResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ContextApi.get']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ContextApi - factory interface
 * @export
 */
export const ContextApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContextApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves the session context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(options?: RawAxiosRequestConfig): AxiosPromise<ContextResponse> {
            return localVarFp.get(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContextApi - object-oriented interface
 * @export
 * @class ContextApi
 * @extends {BaseAPI}
 */
export class ContextApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves the session context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContextApi
     */
    public get(options?: RawAxiosRequestConfig) {
        return ContextApiFp(this.configuration).get(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create notification.
         * @param {CreateNotificationRequest} [createNotificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotification: async (createNotificationRequest?: CreateNotificationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNotificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the notification.
         * @param {string} notificationId The id of the notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotification: async (notificationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('getNotification', 'notificationId', notificationId)
            const localVarPath = `/notifications/{notificationId}`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the notifications.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create notification.
         * @param {CreateNotificationRequest} [createNotificationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNotification(createNotificationRequest?: CreateNotificationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNotification(createNotificationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.createNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieves the notification.
         * @param {string} notificationId The id of the notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotification(notificationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotification(notificationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.getNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieves the notifications.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifications(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotifications(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.getNotifications']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create notification.
         * @param {NotificationsApiCreateNotificationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNotification(requestParameters: NotificationsApiCreateNotificationRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<NotificationDetail> {
            return localVarFp.createNotification(requestParameters.createNotificationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the notification.
         * @param {NotificationsApiGetNotificationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotification(requestParameters: NotificationsApiGetNotificationRequest, options?: RawAxiosRequestConfig): AxiosPromise<NotificationDetail> {
            return localVarFp.getNotification(requestParameters.notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the notifications.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(options?: RawAxiosRequestConfig): AxiosPromise<NotificationsResponse> {
            return localVarFp.getNotifications(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createNotification operation in NotificationsApi.
 * @export
 * @interface NotificationsApiCreateNotificationRequest
 */
export interface NotificationsApiCreateNotificationRequest {
    /**
     * 
     * @type {CreateNotificationRequest}
     * @memberof NotificationsApiCreateNotification
     */
    readonly createNotificationRequest?: CreateNotificationRequest
}

/**
 * Request parameters for getNotification operation in NotificationsApi.
 * @export
 * @interface NotificationsApiGetNotificationRequest
 */
export interface NotificationsApiGetNotificationRequest {
    /**
     * The id of the notification.
     * @type {string}
     * @memberof NotificationsApiGetNotification
     */
    readonly notificationId: string
}

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @summary Create notification.
     * @param {NotificationsApiCreateNotificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public createNotification(requestParameters: NotificationsApiCreateNotificationRequest = {}, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).createNotification(requestParameters.createNotificationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the notification.
     * @param {NotificationsApiGetNotificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getNotification(requestParameters: NotificationsApiGetNotificationRequest, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getNotification(requestParameters.notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the notifications.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getNotifications(options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getNotifications(options).then((request) => request(this.axios, this.basePath));
    }
}




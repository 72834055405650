import { notificationsApi } from '@/integrations/api';
import { Notification } from '@rchitected/api';
import { translations } from '@rchitected/locale';
import {
  Checkbox,
  Form,
  InputWithLabel,
  InstanceProps,
  ModalHeader,
  ModalLayout,
  ModalPrimaryButton,
  ModalSecondaryButton,
  SubmitError,
  ValidatedField
} from '@rchitected/ui';
import classnames from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

interface Props extends InstanceProps<Notification> {}

const schema = yup
  .object({
    content: yup.string().required().label(translations.fields.notification.content.label),
    broadcast: yup.boolean().required().label(translations.fields.notification.broadcast.label),
    topic: yup
      .string()
      .when('broadcast', { is: (v: boolean) => !v, then: (s) => s.required() })
      .label(translations.fields.notification.topic.label)
  })
  .required();

export const CreateNotificationModal = React.forwardRef<HTMLDivElement, Props>(
  ({ initialFocus, className, onAction, onClose }, ref) => {
    const intl = useIntl();

    const initialValues = React.useMemo(() => ({ content: '', broadcast: true, topic: undefined }), []);

    const onSubmit = React.useCallback(
      async ({ content, broadcast, topic }: yup.InferType<typeof schema>) => {
        const notification = await notificationsApi
          .createNotification({
            createNotificationRequest: { content, broadcast, topic: broadcast ? undefined : topic }
          })
          .then((r) => r.data);

        await onAction(notification);
      },
      [onAction]
    );

    return (
      <div {...{ ref }} className={classnames(className, 'max-w-xl')}>
        <ModalLayout>
          <ModalHeader {...{ onClose }}>
            <FormattedMessage id={translations.modals.createProject.title} />
          </ModalHeader>

          <div className="text-sm text-gray-400">
            <FormattedMessage id={translations.modals.createProject.message} />
          </div>

          <Form {...{ initialValues, schema, onSubmit }}>
            {({ values, submitting, submitError, handleSubmit }) => (
              <form className="m-0 grid gap-4" onSubmit={handleSubmit}>
                <ValidatedField
                  ref={initialFocus}
                  as="textarea"
                  field={InputWithLabel}
                  inputClassName="min-h-32"
                  id="content"
                  label={<FormattedMessage id={translations.fields.notification.content.label} />}
                  name="content"
                  type="text"
                  placeholder={intl.formatMessage({ id: translations.fields.notification.content.placeholder })}
                  readOnly={!!submitting}
                />

                <ValidatedField
                  field={Checkbox}
                  id="broadcast"
                  name="broadcast"
                  type="checkbox"
                  readOnly={!!submitting}
                >
                  <FormattedMessage id={translations.fields.notification.broadcast.label} />
                </ValidatedField>

                {!values.broadcast && (
                  <ValidatedField
                    field={InputWithLabel}
                    id="topic"
                    label={<FormattedMessage id={translations.fields.notification.topic.label} />}
                    name="topic"
                    type="text"
                    placeholder={intl.formatMessage({ id: translations.fields.notification.topic.placeholder })}
                    readOnly={!!submitting}
                  />
                )}

                <SubmitError error={submitError} />

                <div className="flex flex-row-reverse gap-2">
                  <ModalPrimaryButton onAction={() => handleSubmit()}>
                    <FormattedMessage id={translations.buttons.create} />
                  </ModalPrimaryButton>

                  <ModalSecondaryButton {...{ onClose }}>
                    <FormattedMessage id={translations.buttons.back} />
                  </ModalSecondaryButton>
                </div>
              </form>
            )}
          </Form>
        </ModalLayout>
      </div>
    );
  }
);

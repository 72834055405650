import { generatePath } from 'react-router';

interface RouteConfig {
  [x: string]: string | RouteConfig | Function;
}

const createRoutes = <C extends RouteConfig>(base: string, config: C): C => {
  return Object.entries(config).reduce<C>((config, [key, value]) => {
    switch (typeof value) {
      case 'string': {
        // @ts-ignore
        config[key] = `${base}${value}`;
        break;
      }
      case 'function': {
        // @ts-ignore
        config[key] = value;
        break;
      }
      case 'object': {
        // @ts-ignore
        config[key] = createRoutes(base, value);
        break;
      }
    }

    return config;
  }, config);
};

const login = `/login`;
const register = `/register`;

const forgotPassword = createRoutes(`/forgot-password`, {
  send: '',
  reset: '/reset'
});

const dashboard = `/dashboard`;

const notifications = createRoutes('/notifications', {
  root: '',
  new: '/new',
  one: '/:notificationId',
  getOne: (o: { notificationId: string }, path?: string) => generatePath(path ?? notifications.one, o)
});

export const urls = {
  login,
  register,
  forgotPassword,
  dashboard,
  notifications
};

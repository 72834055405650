import { Configuration, ContextApi, NotificationsApi } from '@rchitected/api';
import { getAccessToken, renewAccessTokenInterceptor } from '@rchitected/utility';
import axios from 'axios';

import { API_URL } from '@/config';

const config = new Configuration({ accessToken: getAccessToken });

const apiClient = axios.create({ baseURL: API_URL });

apiClient.interceptors.response.use(undefined, renewAccessTokenInterceptor);

export const contextApi = new ContextApi(config, undefined, apiClient);
export const notificationsApi = new NotificationsApi(config, undefined, apiClient);

import { useRemoteData, useStrictParams } from '@rchitected/hooks';
import { Button, Input, InputWithLabel, Modal, useModal } from '@rchitected/ui';
import React from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';
import * as yup from 'yup';

import { Card } from '@/components/Card';
import { Heading, HeadingDetails, HeadingTitle } from '@/components/Heading';
import { Page } from '@/components/Page';
import { ProjectDetails } from '@/components/ProjectDetails';
import { notificationsApi } from '@/integrations/api';
import { UpdateVersionModal } from '@/modals/UpdateVersionModal';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { translations } from '@rchitected/locale';
import classNames from 'classnames';
import { useToggle } from 'react-use';

const versions: Version[] = [
  { version: '1.2.2', rollout: 0.75, active: true, updatedAt: new Date() },
  { version: '1.2.1', rollout: 0.5, active: true, updatedAt: new Date() },
  { version: '1.2.0', rollout: 1, active: false, updatedAt: new Date() },
  { version: '1.1.0', rollout: 1, active: false, updatedAt: new Date() },
  { version: '1.0.0', rollout: 1, active: false, updatedAt: new Date() }
];

interface Version {
  version: string;
  rollout: number;
  active: boolean;
  updatedAt: Date;
}

const schema = yup
  .object({
    name: yup.string().required()
  })
  .required();

export const ProjectVersions: React.FC<{ notificationId: string }> = ({ notificationId }) => {
  const modal = useModal(UpdateVersionModal);

  const versions = useRemoteData({ key: 'useVersions', notificationId }, async ({ notificationId }) =>
    notificationsApi.getNotification({ notificationId }).then((r) => r.data)
  );

  return (
    <React.Fragment>
      <div className="flex max-w-2xl flex-col gap-4">
        <div className="flex items-center justify-between">
          <h3 className="text-base font-semibold leading-6 text-gray-900">Versions ({versions.data?.length})</h3>

          <Button
            appearance="primary"
            className="h-10 px-4"
            type="button"
            onClick={() =>
              modal
                .open({ notificationId })
                .then(() => versions.mutate())
                .catch(() => void 0)
            }
          >
            Create version
          </Button>
        </div>

        <div className="flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Version
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Rollout
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Status
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Updated at
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {versions.data
                      ?.map((version, index) => ({
                        version,
                        status:
                          index === 0
                            ? ('active' as const)
                            : version.rollout === 1
                            ? ('completed' as const)
                            : ('rolled_back' as const)
                      }))
                      .map(({ version: { id, rollout, updatedAt }, status }) => (
                        <tr key={id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {id}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <FormattedNumber style="percent" value={rollout} />
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span
                              className={classNames(
                                'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset',
                                {
                                  'bg-green-50 text-green-700 ring-green-600/20': status !== 'rolled_back',
                                  'bg-red-50 text-red-700 ring-red-600/20': status === 'rolled_back'
                                }
                              )}
                            >
                              {status === 'active' ? 'Active' : status === 'completed' ? 'Completed' : 'Rolled back'}
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <FormattedDate dateStyle="full" timeStyle="short" value={updatedAt} />
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            {status === 'active' && (
                              <button
                                className="font-semibold text-indigo-600 hover:text-indigo-900"
                                type="button"
                                onClick={() =>
                                  modal
                                    .open({ notificationId, version: { id, rollout } })
                                    .then(() => versions.mutate())
                                    .catch(() => void 0)
                                }
                              >
                                Edit<span className="sr-only">, {id}</span>
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal {...modal.props} />
    </React.Fragment>
  );
};

const Instructions: React.FC<{ apiKey?: string }> = ({ apiKey }) => {
  const [open, setOpen] = useToggle(false);

  return (
    <Card className="flex max-w-2xl flex-col gap-4 p-6">
      <div className="flex items-center justify-between">
        <h2 className="text-sm font-semibold">Instructions</h2>

        <Button type="button" className="h-10 w-10" onClick={() => setOpen(!open)}>
          {open ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" />}
        </Button>
      </div>

      <div>
        <InputWithLabel
          className="w-full"
          id="api-key"
          label={<FormattedMessage id={translations.fields.project.apiKey.label} />}
          name="apiKey"
          readOnly
          type="text"
          value={apiKey ?? ''}
        />
      </div>

      {!!open && (
        <Input as="div" className="h-auto">
          <pre className="text-sm">yarn add @forcie/client @forcie/react</pre>

          <pre className="text-sm">
            {`
import { ForcieClient } from '@forcie/client';
import { useForcieUpdate } from '@forcie/react';

// Your application's version. Most likely an env variable.
const VERSION = '1.2.3';
const FORCIE_API_KEY = '`}
            <span className="font-semibold">{`${apiKey ?? '{{API_KEY}}'}`}</span>
            {`';

const client = new ForcieClient({
  apiKey: FORCIE_API_KEY
});

const App: React.FC = () => {
  const [{ loading, updateRequired, error }] = useForcieUpdate({
    client,
    version: VERSION
  }, []);

  if (loading) return <div>Loading...</div>;

  if (updateRequired) {
    alert('Update required. Feel free to notify the user.');
    return null;
  }

  return <div>Your application</div>;
};
`}
          </pre>
        </Input>
      )}
    </Card>
  );
};

export const NotificationDetailPage: React.FC = () => {
  const { notificationId } = useStrictParams<{ notificationId: string }>();

  const project = useRemoteData({ key: 'useNotification', notificationId }, async ({ notificationId }) =>
    notificationsApi.getNotification({ notificationId }).then((r) => r.data)
  );

  return (
    <Page>
      <Heading>
        <HeadingTitle>Notifications</HeadingTitle>

        <HeadingDetails>Project name</HeadingDetails>
      </Heading>

      <Card className="max-w-2xl">
        <ProjectDetails name={project.data?.content} className="p-6">
          <a href="#" className="hidden text-sm font-semibold leading-6 text-gray-900 sm:block">
            Edit
          </a>
        </ProjectDetails>
      </Card>

      {/* <Instructions apiKey={project.data?.apiKey} />

      <ProjectVersions {...{ projectId }} /> */}
    </Page>
  );
};

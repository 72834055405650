import { useRemoteData } from '@rchitected/hooks';
import { Button, Modal, useModal } from '@rchitected/ui';
import React from 'react';
import { useNavigate } from 'react-router';

import { notificationsApi } from '@/integrations/api';

import { Heading, HeadingFilters, HeadingTitle } from '@/components/Heading';
import { Page } from '@/components/Page';
import { CreateNotificationModal } from '@/modals/CreateNotificationModal';
import { urls } from '@/utils/url';
import classNames from 'classnames';
import { FormattedDate } from 'react-intl';

export const NotificationListPage: React.FC = () => {
  const modal = useModal(CreateNotificationModal);
  const navigate = useNavigate();

  const notifications = useRemoteData({ key: 'getNotifications' }, async () =>
    notificationsApi.getNotifications().then((r) => r.data.data)
  );

  return (
    <React.Fragment>
      <Page>
        <Heading>
          <HeadingTitle>Notifications</HeadingTitle>

          <HeadingFilters />
        </Heading>

        <div className="flex max-w-7xl flex-col gap-4">
          <div className="flex items-center justify-between">
            <Button
              appearance="primary"
              className="h-10 px-4"
              type="button"
              onClick={() =>
                modal
                  .open({})
                  .then(({ id }) => navigate(urls.notifications.getOne({ notificationId: id })))
                  .catch(() => void 0)
              }
            >
              Create notification
            </Button>
          </div>

          <div className="flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Message
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Status
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Sent
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {notifications.data?.map((notification) => (
                        <tr key={notification.id}>
                          <td className="text-ellipsis py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {notification.content}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span
                              className={classNames(
                                'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset',
                                {
                                  'bg-green-50 text-green-700 ring-green-600/20': notification.broadcast,
                                  'bg-red-50 text-red-700 ring-red-600/20':
                                    !notification.broadcast && notification.topic,
                                  'bg-yellow-50 text-yellow-700 ring-yellow-800/20':
                                    !notification.broadcast && !notification.topic
                                }
                              )}
                            >
                              {notification.broadcast ? 'broadcast' : `topic:${notification.topic}` ?? 'user'}
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <FormattedDate dateStyle="long" timeStyle="short" value={notification.createdAt} />
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <button
                              className="font-semibold text-indigo-600 hover:text-indigo-900"
                              type="button"
                              onClick={() =>
                                modal
                                  .open({ notification })
                                  .then(() => notifications.mutate())
                                  .catch(() => void 0)
                              }
                            >
                              Edit<span className="sr-only">, notification</span>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>

      <Modal {...modal.props} />
    </React.Fragment>
  );
};

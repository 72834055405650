import { translations } from '@rchitected/locale';
import {
  Form,
  InputWithLabel,
  InstanceProps,
  ModalHeader,
  ModalLayout,
  ModalPrimaryButton,
  ModalSecondaryButton,
  SubmitError,
  ValidatedField
} from '@rchitected/ui';
import classnames from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

interface Props
  extends InstanceProps<ProjectVersion, { projectId: string; version?: { id: string; rollout: number } }> {}

const schema = yup
  .object({
    id: yup.string().required().label(translations.fields.version.id.label),
    rollout: yup.number().integer().min(0).max(100).required().label(translations.fields.version.rollout.label)
  })
  .required();

export const UpdateVersionModal = React.forwardRef<HTMLDivElement, Props>(
  ({ data: { projectId, version }, initialFocus, className, onAction, onClose }, ref) => {
    const intl = useIntl();

    const initialValues = React.useMemo(
      () => ({ id: version?.id ?? '', rollout: (version?.rollout ?? 1) * 100 }),
      [version]
    );

    const onSubmit = React.useCallback(
      async ({ id, rollout }: yup.InferType<typeof schema>) => {
        rollout = rollout / 100;

        const data = version
          ? await projectApi
              .updateVersion({ projectId, versionId: version.id, updateVersionRequest: { rollout } })
              .then((r) => r.data)
          : await projectApi.createVersion({ projectId, createVersionRequest: { id, rollout } }).then((r) => r.data);

        await onAction(data);
      },
      [onAction, projectId, version]
    );

    return (
      <div {...{ ref }} className={classnames(className, 'max-w-xl')}>
        <ModalLayout>
          <ModalHeader {...{ onClose }}>
            <FormattedMessage id={translations.modals.createVersion.title} />
          </ModalHeader>

          <div className="text-sm text-gray-400">
            <FormattedMessage id={translations.modals.createVersion.message} />
          </div>

          <Form {...{ initialValues, schema, onSubmit }}>
            {({ submitting, submitError, handleSubmit }) => (
              <form className="m-0 grid gap-4" onSubmit={handleSubmit}>
                <ValidatedField
                  ref={initialFocus}
                  field={InputWithLabel}
                  id="id"
                  label={<FormattedMessage id={translations.fields.version.id.label} />}
                  name="id"
                  placeholder={intl.formatMessage({ id: translations.fields.version.id.placeholder })}
                  readOnly={!!submitting}
                />

                <ValidatedField
                  field={InputWithLabel}
                  id="rollout"
                  label={<FormattedMessage id={translations.fields.version.rollout.label} />}
                  name="rollout"
                  placeholder={intl.formatMessage({ id: translations.fields.version.rollout.placeholder })}
                  readOnly={!!submitting}
                />

                <SubmitError error={submitError} />

                <div className="flex flex-row-reverse gap-2">
                  <ModalPrimaryButton onAction={() => handleSubmit()}>
                    <FormattedMessage id={translations.buttons.create} />
                  </ModalPrimaryButton>

                  <ModalSecondaryButton {...{ onClose }}>
                    <FormattedMessage id={translations.buttons.back} />
                  </ModalSecondaryButton>
                </div>
              </form>
            )}
          </Form>
        </ModalLayout>
      </div>
    );
  }
);

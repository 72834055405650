import { useRemoteData } from '@rchitected/hooks';
import { useLocalStorage } from 'react-use';
import { createContainer } from 'unstated-next';

import { Locale } from '../locales';

interface UseLanguageOptions {
  locale?: Locale;
}

export const getLocale = (value: string) => {
  return Object.values(Locale).find((l) => l === value) ? (value as Locale) : undefined;
};

const useLanguage = ({ locale: defaultLocale }: UseLanguageOptions = {}) => {
  const [locale, setLocale] = useLocalStorage(
    `notifire.locale`,
    defaultLocale ?? getLocale(window.navigator.language?.substring(0, 2)) ?? Locale.English
  );

  const messages = useRemoteData({ key: 'useMessages', locale, skip: !locale }, ({ locale }) =>
    import(`../lang/${locale}.json`).then((r) => r.default)
  );

  return [locale!, setLocale, messages] as const;
};

export const Language = createContainer(useLanguage);
